import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import { Container, Row, Col } from 'react-bootstrap'
import { ArrowDown } from '../../components/Icons'
import SEO from '../../components/SEO'
import './style.scss'
import Layout from '../../layouts/Main'

export default function Detail({ location, pageContext, data }) {
  const {
    title,
    client,
    category,
    year,
    testimonial,
    cover2,
    meta,
  } = data.markdownRemark.frontmatter

  const { next } = pageContext
  const DURATION = 2

  const wrapperVariants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: DURATION,
        when: 'beforeChildren',
        delayChildren: 0.5,
        type: 'spring',
        stiffness: 10,
      },
    },
  }

  const heroVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1,
        type: 'tween',
        ease: 'easeInOut',
      },
    },
  }

  const metaVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  }

  const itemVariants = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 20,
      },
    },
  }

  return (
    <Layout location={location}>
      <SEO title={title} description={meta.description} />
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={wrapperVariants}
      >
        <div className="mrd-l-work">
          <motion.section
            initial="hidden"
            animate="visible"
            variants={heroVariants}
            className="mrd-l-work__hero"
          >
            <Img
              style={{ height: '100%' }}
              fluid={{ ...cover2.childImageSharp.fluid, aspectRatio: 16 / 8 }}
              alt={title}
            />
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 1.5, duration: 1 },
              }}
            >
              <div
                // style={{ backgroundColor: color }}
                className="mrd-title__primary mrd-l-work__title"
              >
                {title}
                <p>{category}</p>
              </div>
            </motion.h1>
          </motion.section>
          <motion.section
            // style={{ backgroundColor: color }}
            className="mrd-l-work__intro"
            variants={metaVariants}
            initial="hidden"
            animate="visible"
          >
            <Container>
              <Row className="justify-content-center">
                <Col xl={10}>
                  <motion.h3 variants={itemVariants}>Challenge</motion.h3>
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 9, offset: 1 }}>
                  <motion.h2
                    variants={itemVariants}
                    className="mrd-l-work__challenge"
                  >
                    {meta.challenge}
                  </motion.h2>
                </Col>
              </Row>

              <Row className="justify-content-center align-items-start">
                <Col xl={{ span: 5 }}>
                  <motion.h3 variants={itemVariants}>
                    A propos du projet
                  </motion.h3>
                  <motion.p variants={itemVariants}>
                    {meta.description}
                  </motion.p>
                  {meta.link && (
                    <motion.p>
                      <a
                        href={meta.link}
                        className="custom-link"
                        target="_blank"
                        rel="noreferrer"
                        variants={itemVariants}
                      >
                        Voir le projet
                      </a>
                    </motion.p>
                  )}
                </Col>
                <Col xl={{ span: 4, offset: 1 }}>
                  <Row>
                    <Col xl={6} style={{ marginBottom: '1rem' }}>
                      <motion.h3 variants={itemVariants}>Rôle</motion.h3>
                      {meta.rules.split(',').map((item, k) => (
                        <motion.span variants={itemVariants} key={k}>
                          {item} <br />
                        </motion.span>
                      ))}
                    </Col>
                    <Col xl={6}>
                      <motion.h3 variants={itemVariants}>Pour</motion.h3>
                      <motion.p variants={itemVariants}>{client}</motion.p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <motion.h3 variants={itemVariants}>Année</motion.h3>
                      <motion.p variants={itemVariants}>{year}</motion.p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </motion.section>

          <motion.section
            variants={itemVariants}
            className="mrd-l-work__section"
          >
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </motion.section>

          {/* TESTIMONIALS */}
          {testimonial && (
            <motion.section
              variants={itemVariants}
              className="mrd-l-work__testimonials mrd-l-work__section"
            >
              <Container>
                <Row className="justify-content-center">
                  <Col xl={5}>
                    <h1>Feedback</h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: testimonial.text }}
                    />
                    <h2 className="author">
                      {testimonial.author} - {testimonial.job}
                    </h2>
                  </Col>
                </Row>
              </Container>
            </motion.section>
          )}

          {next ? (
            <motion.section
              variants={itemVariants}
              className="mrd-l-work__next-work"
            >
              <p>Projet suivant</p>
              <Link className="name" to={`/works/${next.frontmatter.slug}`}>
                {next.frontmatter.title}
              </Link>
              <Link
                to={`/projets/${next.frontmatter.slug}`}
                style={{ borderColor: next.frontmatter.colorText }}
                className={`next-arrow`}
              >
                <ArrowDown color={next.frontmatter.colorText} />
              </Link>
            </motion.section>
          ) : (
            <motion.section
              variants={itemVariants}
              className="mrd-l-work__next-work"
            >
              <span style={{ opacity: 0.5 }}>
                Je reste disponible pour toute collaboration
              </span>
              <a className="custom-link" href="mailto:charlesabedie@gmail.com">
                Contactez-moi
              </a>
            </motion.section>
          )}
        </div>
      </motion.div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        category
        color
        client
        year
        colorText
        meta {
          description
          rules
          link
          challenge
        }
        testimonial {
          author
          job
          text
        }
        cover {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cover2 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
